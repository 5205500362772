import { useState, useEffect } from 'react';
import axios from 'axios';

function Result () {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get("id");
  const month = params.get("month");
  const [ assessment, setAssessment ] = useState({});
  const [ developmentArea, setDevelopmentArea ] = useState([]);
  const keyTexts = {
    사회성: "sociability",
    정서: "emotion",
    운동: "exercise",
    언어: "language",
    인지: "recognition",
    상호작용: "interaction",
  }
  useEffect(async ()=> {
    const assessmentRes = await axios.get(`https://asia-northeast3-babytestapp.cloudfunctions.net/mbi/assessment?id=${id}&month=${month}`);
    const assessmentDatas = assessmentRes.data.data;
    const samplesRes = await axios.get(`https://asia-northeast3-babytestapp.cloudfunctions.net/mbi/video/sample?month=${month}`);
    const samplesDatas = samplesRes.data.data;
    const area = {};
    samplesDatas.map((el, idx) => {
      if(idx === 0) return ;
      area[el.developmentArea1] = el.title;
    })
    setAssessment(assessmentDatas[0]);
    const developmentArea = Object.entries(area);
    developmentArea.push(["상호작용", "상호작용"]);
    setDevelopmentArea(developmentArea);
  },[]);
  
  return (
      <div className="w-full h-full bg-white flex justify-center items-center pt-4">
        <div className="w-10/12 flex flex-col">
          <div className="grid grid-cols-1 gap-1 text-base font-bold">
            {developmentArea.map(([key, value], idx) => {
              return (
                <div className="shadow-sm shadow-gray-500 border py-1 rounded-lg flex" key={idx}>
                  <div className={`w-9/12 border-r flex ${value.length > 12 ? "text-sm" : ""} items-center justify-center`}>
                    {value}
                  </div>
                  <div className="w-3/12 flex justify-center items-center h-8">
                    {assessment[keyTexts[key]] === "2" ? <div className="flex flex-col items-center justify-center justify-center"><div className="w-6 h-6 rounded-full bg-green-500" /> {/*<div className="text-xs">빠른/정상 발달</div>*/}</div>
                    :assessment[keyTexts[key]] === "1" ? <div className="flex flex-col items-center justify-center"><div className="w-6 h-6 rounded-full bg-yellow-500" /> {/*<div className="text-xs">경계선 발달</div>*/}</div>
                    :assessment[keyTexts[key]] === "0" ? <div className="flex flex-col items-center justify-center"><div className="w-6 h-6 rounded-full bg-red-600" /> {/*<div className="text-xs">발달지연 의심</div>*/}</div> : <div className="text-sm">미평가</div>}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    );
}

export default Result;